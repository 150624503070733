@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP');

@font-face {
  font-family: 'kosugimaru';
  src: url('./fonts/KosugiMaru-Regular.ttf') format('truetype');
  }

@font-face {
    font-family: 'logotype';
    src: url('./fonts/07LogoTypeGothic7.ttf') format('truetype');
    }

.App {
  /*text-align: center;*/
  font-size: 62.5%;
  color:#424242;
  font-family: 'Noto Sans JP', sans-serif;
  letter-spacing: 0.05em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Navigation{
  font-family: "kosugimaru", sans-serif;
  background-color: #00695B;
  font-size: 0.8rem;
  padding:0 !important;
  border-bottom: 1px solid #BDBDBD;
  
}

.navbar-nav{
  border-right: 1px solid #BDBDBD;
}

.nav-link {
  color :#BDBDBD!important;
  font-weight : normal!important;
  border-left: 1px solid #BDBDBD;
  padding-left:20px!important;
  padding-right:20px!important;
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.nav-link:hover{
  color: #fff!important;
  font-weight : normal!important;
}

.navbar-toggler{
  border-color: #00695B!important;
  outline:0!important;
}

.navbar-toggler:active{
  border-color: #00695B!important;

}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")!important;
}

.Header-img-logo{
  margin-top: 5px;
  margin-bottom: 5px;
}

.Header h1{
  font-family: "kosugimaru", sans-serif;
  font-size: 0.9rem;
}

footer{
  background-color: #005348;
}

footer h2{
  color: #fff;
  font-size: 1rem;
  margin-bottom: 20px;
}

footer ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

footer li{
  font-size: 0.8rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Footer{
  padding-top: 30px;
  padding-bottom: 30px;
}

.FooterLinkCol{
  margin-top: 20px;
}

.FooterLink{
  color : #BDBDBD;
  text-decoration : none;
  cursor:default;
}

.FooterLink:hover{
  color : #fff;
  text-decoration : none;
  cursor:default;
}

.FooterCopyRightRow{
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
}

.FooterCopyRightRow p{
  margin-top: 60px;
}

.slick-prev:before, .slick-next:before { 
  color:#003830 !important;
}

.SlickAppIntro-wrap{
  /*background-color: #F3FEFC;*/
  /*background-color: #249081;*/
  margin-bottom: 25px;
  background: radial-gradient(#fff, #249081);
}

.NewInformation{
  margin-top: 30px;
  margin-bottom: 40px;
}

.NewInformation table{
  font-size: 0.8rem;
}

.NewInformation h2{
  margin-left: 20px;
  font-size: 1rem;
  color:#A0000F;
}

.NewInformationNoData{
  margin-left: 25px;
  font-size: 0.8rem;
}

.HistoryList{
  margin-top:10px;
  margin-bottom: 40px;
}

.HistoryListCard{ 
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.HistoryListNoData{
  margin-top:10px;
  font-size: 0.9rem;
}

.HistoryListCardFooter ul{
  font-size: 0.8rem;
  padding-left: 0;
  margin:0;
  list-style: none;
}

.HistoryListCardTitle{
  font-size: 0.9rem;
  font-weight: bold;
}

.HistoryListCardFooter span{
  font-size: 0.8rem;
}

.HistoryListPagination{
  margin-top:10px;
  margin-bottom:10px;
}

.TermsOfServiceDisplay{
  margin-bottom: 40px;
}

.TermsOfServiceDisplay p{
  margin-left:15px;
  margin-right: 15px;
  }

.TermsOfServiceDisplay h2{
  font-size: 1.5rem;
  text-align: center;
  margin:25px 0 25px 0;
}

.TermsOfServiceDisplay h3{
  font-size: 1.2rem;
  background-color: #249081;
  color: #fff;
  padding: 5px 0px 5px 15px;
}

.TermsOfServiceDisplay section{
  font-size: 0.9rem;
}

.TermsOfServiceDisplay li{
  margin: 5px 0 5px 0;
}

.TermsOfServiceDisplay ul{
  list-style: none;
}

.TermsOfServiceDisplay ul li {
  position: relative;
}

.TermsOfServiceDisplay ul li::after {
  display: block;
  content: '';
  position: absolute;
  top: .6em;
  left: -1em;
  width: 8px;
  height: 4px;
  border-left: 2px solid #005348;
  border-bottom: 2px solid #005348;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
 
}

.PrivacyPolicyDisplay{
  margin-bottom: 40px;
}

.PrivacyPolicyDisplay p{
  margin-left:15px;
  margin-right: 15px;
}

.PrivacyPolicyDisplay h2{
  font-size: 1.5rem;
  text-align: center;
  margin:25px 0 25px 0;
}

.PrivacyPolicyDisplay h3{
  font-size: 1.2rem;
  background-color: #249081;
  color: #fff;
  padding: 5px 0px 5px 15px;
}

.PrivacyPolicyDisplay section{
  font-size: 0.9rem;
}

.PrivacyPolicyDisplay li{
  margin: 5px 0 5px 0;
}

.PrivacyPolicyDisplay h4{
  font-size: 1rem;
  margin-left: 15px;
}

.PrivacyPolicyDisplay ul{
  list-style: none;
}

.PrivacyPolicyDisplay ul li {
  position: relative;
}

.PrivacyPolicyDisplay ul li::after {
  display: block;
  content: '';
  position: absolute;
  top: .6em;
  left: -1em;
  width: 8px;
  height: 4px;
  border-left: 2px solid #005348;
  border-bottom: 2px solid #005348;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
 
}

.ContactForm{
  margin-top: 20px;
  margin-bottom: 40px;
}

.ImgW992Up{ display: block !important; }
.ImgW576Up992Down{ display: none !important; }
.ImgW576Down{ display: none !important; }
.ContentIntroductionRow{justify-content: start;}

@media only screen and (max-width: 992px) {
  .ImgW992Up{ display: none !important; }
  .ImgW576Up992Down{ display: block !important; }
  .ImgW576Down{ display: none !important; }
  .ContentIntroductionRow{justify-content: start;}
}

@media only screen and (max-width: 576px) {
  .ImgW992Up{ display: none !important; }
  .ImgW576Up992Down{ display: none !important; }
  .ImgW576Down{ display: block !important; }
  .ContentIntroductionRow{justify-content: center;}
}

.ContentIntroduction{
  margin-top: 60px;
}

.ContentIntroduction h2{
  margin-left: 20px;
  font-size: 1rem;
}

.ContentIntroductionCard{
  text-align: center;
  
}

.ContentIntroductionCard:hover{
  background-color:#F0F0F0; 
}

.ContentIntroductionLink{
  color : inherit;
  text-decoration : none;
  cursor:default;
}

.ContentIntroductionLink:hover{
  color : inherit;
  text-decoration : none;
  cursor:default;
}

.ContentIntroductionDetail{
  margin-top: 15px;
  margin-bottom: 20px;;
  font-size: 0.9rem;
 
}

.ContentIntroductionDetail h2{
  font-size: 1.5rem;
  padding-left: 10px;
}

.ContentIntroductionDetailSummary{
  padding-left: 10px;
}

.ContentIntroductionDetail h3{
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.ContentIntroductionDetailContentTitle{
  border-bottom: solid 1px #E0E0E0;
}

.ContentIntroductionDetailContent{
  margin-top: 20px;
  border : solid 1px #E0E0E0;
  border-radius: 5px;
  padding: 15px;
}

.ContentIntroductionDetailContent p{
  line-height:200%;
}

.ContentIntroductionDetailContentData{
  text-align: center;
  padding-top: 20px;
}

.ContentIntroductionDetailContentData p{
  text-align: left;
}